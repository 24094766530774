export enum ChartComponent {
  Title = 'title',
  Grid = 'grid',
  Legend = 'legend',
  Tooltip = 'tooltip',
  Series = 'series',
  XAxis = 'xAxis',
  YAxis = 'yAxis',
  AxisPointer = 'axisPointer',
  Toolbox = 'toolbox',
  Graphic = 'graphic',
  DataZoom = 'dataZoom',
  Bar = 'bar',
  Line = 'line',
  LineAndBar = 'lineAndBar'
}
